import { TFunction } from 'react-i18next';

import { GET_PAGINATED_BONUS_POINTS, GET_PAGINATED_PLAY_HISTORY } from './ranking-reports-queries';

export const REPORT_TYPES = {
  PLAY_HISTORY: 'play history',
  BONUS_POINTS: 'bonus points',
};

export const reportConfigs = (t: TFunction) => ({
  ['PLAY_HISTORY']: {
    query: GET_PAGINATED_PLAY_HISTORY,
    filters: (startDate: Date, endDate: Date) => ({
      tournamentEnd: {
        after: startDate.toISOString(),
        before: endDate.toISOString(),
      },
    }),
    columns: [
      { key: 'tournamentId', getValue: (e) => e.tournamentId, title: t('tournament id') },
      { key: 'tournamentName', getValue: (e) => e.tournamentName, title: t('tournament name') },
      {
        key: 'tournamentStart',
        getValue: (e) => (e.tournamentStart ? new Date(e.tournamentStart).toDateString() : '-'),
        title: t('tournament start'),
      },
      {
        key: 'tournamentEnd',
        getValue: (e) => (e.tournamentEnd ? new Date(e.tournamentEnd).toDateString() : '-'),
        title: t('tournament end'),
      },
      { key: 'eventName', getValue: (e) => e.eventName, title: t('name') },
      { key: 'playerId', getValue: (e) => (e.playerId ? e.playerId : '-'), title: t('player id'), sort: false },
      { key: 'playerName', getValue: (e) => e.playerName, title: t('player name'), sort: false },
      { key: 'singlesPoints', getValue: (e) => e.singlesPoints || 0, title: t('singles points') },
      { key: 'doublesPoints', getValue: (e) => e.doublesPoints || 0, title: t('doubles points') },
      { key: 'participationPoints', getValue: (e) => e.participationPoints || 0, title: t('participation points') },
      { key: 'bonusPoints', getValue: (e) => e.bonusPoints || 0, title: t('bonus points') },
      { key: 'playerCity', getValue: (e) => e.playerCity, title: t('city'), sort: false },
      { key: 'playerState', getValue: (e) => e.playerState, title: t('state'), sort: false },
      { key: 'level', getValue: (e) => e.level, title: t('level') },
      { key: 'pointsTable', getValue: (e) => e.pointsTable, title: t('points table') },
    ],
  },
  ['BONUS_POINTS']: {
    query: GET_PAGINATED_BONUS_POINTS,
    filters: (startDate: Date, endDate: Date) => ({
      from: startDate.toISOString(),
      to: endDate.toISOString(),
    }),
    columns: [
      { key: 'tournamentId', getValue: (e) => e.tournamentId, title: t('tournament id'), sort: false },
      { key: 'tournamentName', getValue: (e) => e.tournamentName, title: t('tournament name'), sort: false },
      {
        key: 'tournamentStart',
        getValue: (e) => (e.tournamentStart ? new Date(e.tournamentStart).toDateString() : '-'),
        title: t('tournament start'),
      },
      {
        key: 'tournamentEnd',
        getValue: (e) => (e.tournamentEnd ? new Date(e.tournamentEnd).toDateString() : '-'),
        title: t('tournament end'),
      },
      { key: 'event', getValue: (e) => e.event, title: t('event') },
      { key: 'round', getValue: (e) => e.round, title: t('round') },
      { key: 'score', getValue: (e) => e.score, title: t('score'), sort: false },
      { key: 'winnerId', getValue: (e) => e.winnerId, title: t('winner id') },
      { key: 'loserId', getValue: (e) => e.loserId, title: t('loser id') },
      { key: 'bonusPoints', getValue: (e) => e.bonusPoints || 0, title: t('bonus points') },
    ],
  },
});

export type ReportType = keyof typeof reportConfigs;
