import graphql from 'graphql-tag';

export const GET_PAGINATED_PLAY_HISTORY = graphql`
  query getPaginatedPlayHistory($skip: Int, $limit: Int, $filter: PlayHistoryFilter, $sort: [EventPlayerSort]) {
    getPaginatedPlayHistory(skip: $skip, limit: $limit, filter: $filter, sort: $sort) {
      items {
        tournamentId
        tournamentStart
        tournamentEnd
        tournamentName
        eventName
        division
        playerId
        playerName
        singlesPoints
        doublesPoints
        participationPoints
        bonusPoints
        totalPoints
        playerCity
        playerState
        level
        pointsTable
      }
      totalItems
    }
  }
`;

export const GET_PAGINATED_BONUS_POINTS = graphql`
  query getPaginatedBonusPoints($skip: Int, $limit: Int, $filter: RankTournamentFilter, $sort: [RankTournamentSort]) {
    getPaginatedBonusPoints(skip: $skip, limit: $limit, filter: $filter, sort: $sort) {
      items {
        id
        tournamentId
        tournamentName
        tournamentEnd
        tournamentStart
        event
        round
        score
        winnerId
        loserId
        bonusPoints
      }
      totalItems
    }
  }
`;
