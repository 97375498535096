import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { OnReportUploadStateChange, ReportUploadState } from 'src/components/generate-report/generate-report';
import { reportConfigs } from 'src/components/rankings-reports/ranking-reports-config';
import { useControlledQuery } from 'src/components/table-controls/table-controls';
import { Direction, PropertySortInput } from 'src/graphql-types/globalTournamentTypes';
import { SortDirectionEnum } from 'src/graphql-types/globalUstaTypes';
import { getOneDayBefore, getOneYearAgo } from 'src/utils/helper/dates';

export const fieldMappings = {
  tournamentStart: 'TOURNAMENT_START',
  tournamentEnd: 'TOURNAMENT_END',
  level: 'LEVEL',
  tournamentName: 'TOURNAMENT_NAME',
  eventName: 'EVENT_NAME',
  matchFormat: 'MATCH_FORMAT',
  singlesPoints: 'SINGLES_POINTS',
  doublesPoints: 'DOUBLES_POINTS',
  bonusPoints: 'BONUS_POINTS',
  participationPoints: 'PARTICIPATION_POINTS',
  totalPoints: 'TOTAL_POINTS',
  pointsTable: 'POINTS_TABLE',
  playerCity: 'CITY',
  playerState: 'STATE',
};

export const getSortOptions = (tableSorts: PropertySortInput[], reportType: string) => {
  return tableSorts?.map(({ property, sortDirection }) => {
    const field = reportType === 'PLAY_HISTORY' ? fieldMappings[property] || 'TOURNAMENT_END' : property;
    return {
      field,
      direction: sortDirection === Direction.ASCENDING ? SortDirectionEnum.ASC : SortDirectionEnum.DESC,
    };
  });
};

export const useRankingReports = (reportType: string) => {
  const { t } = useTranslation();
  const { initialStart, initialEnd } = useMemo(() => {
    const today = new Date();
    return { initialStart: getOneYearAgo(today), initialEnd: getOneDayBefore(today) };
  }, []);

  const transConfig = useMemo(() => reportConfigs(t), [t]);

  const [startDate, setStartDate] = useState<Date>(initialStart);
  const [endDate, setEndDate] = useState<Date>(initialEnd);
  const [reportUploadState, setReportUploadState] = useState<ReportUploadState>('none');
  const [popupClosed, setPopupClosed] = useState(false);
  const [downloadable, setDownloadable] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(transConfig[reportType].filters(initialStart, initialEnd));

  const handleSetReportType = useCallback(
    (type: string) => {
      setFilters(transConfig[type].filters(startDate, endDate));
    },
    [endDate, startDate, transConfig],
  );

  const { data, loading, controlProps, refetch } = useControlledQuery<any>(transConfig[reportType].query, {
    client: meshGatewayClient,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
    getTotalItems: (event: any) =>
      event[reportType === 'PLAY_HISTORY' ? 'getPaginatedPlayHistory' : 'getPaginatedBonusPoints']?.totalItems ?? 0,
    notifyOnNetworkStatusChange: true,
    transformVariables: (v: any) => {
      const { limit, offset, sorts } = v;
      return {
        limit,
        skip: offset,
        filter: filters,
        sort: sorts
          ? getSortOptions(sorts, reportType)
          : { field: 'TOURNAMENT_START', direction: SortDirectionEnum.DESC },
      };
    },
  });

  const handleSetDates = useCallback(
    (dates: { from: Date; to: Date }) => {
      setStartDate(dates.from);
      setEndDate(dates.to);
      setFilters(transConfig[reportType].filters(dates.from, dates.to));
      refetch();
    },
    [transConfig, reportType, refetch],
  );

  const handleSetStartDate = useCallback(
    (date: Date) => {
      setStartDate(date);
      setFilters(transConfig[reportType].filters(date, endDate));
      refetch();
    },
    [endDate, refetch, reportType, transConfig],
  );

  const handleSetEndDate = useCallback(
    (date: Date) => {
      setEndDate(date);
      setFilters(transConfig[reportType].filters(startDate, date));
      refetch();
    },
    [transConfig, reportType, startDate, refetch],
  );

  const paginatedEvents = useMemo(
    () => data?.[reportType === 'PLAY_HISTORY' ? 'getPaginatedPlayHistory' : 'getPaginatedBonusPoints']?.items ?? [],
    [data, reportType],
  );

  const onReportUploadStateChange = useCallback<OnReportUploadStateChange>(
    (state) => {
      if (state === 'processing') {
        setPopupClosed(false);
      }
      setReportUploadState(state);
    },
    [setReportUploadState, setPopupClosed],
  );

  return {
    startDate,
    endDate,
    handleSetStartDate,
    handleSetEndDate,
    handleSetDates,
    reportUploadState,
    onReportUploadStateChange,
    paginatedEvents,
    loading,
    controlProps,
    columns: transConfig[reportType].columns,
    downloadable,
    setDownloadable,
    filters,
    setFilters,
    popupClosed,
    setPopupClosed,
    handleSetReportType,
  };
};
