import { TFunction } from 'i18next';

export const getPlayTrackerPlayersCSVTransform = (t: TFunction) => [
  {
    key: 'name',
    label: t('name'),
  },
  {
    key: 'section',
    label: t('section'),
  },
  {
    key: 'district',
    label: t('district'),
  },
  {
    key: 'age',
    label: t('age'),
  },
  {
    key: 'ballColour',
    label: t('competition level'),
  },
  {
    key: 'coachAssignedLevel',
    label: t('coach level'),
  },
  {
    key: 'redPoints',
    label: t('red points'),
  },
  {
    key: 'totalRedPoints',
    label: t('total red points'),
  },
  {
    key: 'orangePoints',
    label: t('orange points'),
  },
  {
    key: 'totalOrangePoints',
    label: t('total orange points'),
  },
  {
    key: 'redOrangePoints',
    label: t('red + orange points'),
  },
  {
    key: 'totalRedOrangePoints',
    label: t('total red + orange points'),
  },
  {
    key: 'greenPlayPoints',
    label: t('green play points'),
  },
  {
    key: 'greenWinPoints',
    label: t('green win points'),
  },
  {
    key: 'totalGreenPoints',
    label: t('total green points'),
  },
];

export const getPlayTrackerPointsCSVTransform = (t: TFunction) => [
  {
    key: 'eventDate',
    label: t('event date'),
  },
  {
    key: 'eventType',
    label: t('event type'),
  },
  {
    key: 'location',
    label: t('location'),
  },
  {
    key: 'section',
    label: t('section'),
  },
  {
    key: 'district',
    label: t('district'),
  },
  {
    key: 'playerName',
    label: t('player'),
  },
  {
    key: 'ballColour',
    label: t('point type'),
  },
  {
    key: 'playPoints',
    label: t('play points'),
  },
  {
    key: 'winPoints',
    label: t('win points'),
  },
  {
    key: 'totalPoints',
    label: t('total points'),
  },
];
