import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import DatePicker from 'src/components/date-picker/date-picker';
import { TournamentFilter } from 'src/components/tournament-filters/tournament-filters';
import { getClientConfig } from 'src/config/config';

import DropdownContainer from './dropdown-container';
import * as styles from './dropdown-custom.module.less';

interface Props<T = TournamentFilter> {
  from: Date | null;
  to: Date | null;
  setFilters: Dispatch<SetStateAction<any>>;
  initFilter: T;
}

const DropdownCustom = <T extends { from: Date | null; to: Date | null }>({
  from,
  to,
  setFilters,
  initFilter,
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<Date | null>(from);
  const [toDate, setToDate] = useState<Date | null>(to);
  const [dateErrors, setDateErrors] = useState<{ from: boolean; to: boolean; range: boolean }>({
    from: false,
    to: false,
    range: false,
  });
  const { t } = useTranslation();
  const fromDateRef = useRef<{ input?: HTMLInputElement }>();
  const toDateRef = useRef<{ input?: HTMLInputElement }>();
  const clientConfig = useMemo(getClientConfig, []);

  useEffect(() => {
    setFromDate(from);
    setToDate(to);
    setDateErrors({ from: false, to: false, range: false });
  }, [from, to, isOpen]);

  const label = useMemo(() => {
    const dateFrom = dayjs(from).local();
    const dateTo = dayjs(to).local();

    if (!dateFrom.isValid() || !dateTo.isValid()) {
      return t('please select valid date');
    }

    if (dateFrom.isSame(dateTo, 'month')) {
      return t('date same month', { from: dateFrom, to: dateTo });
    }
    if (dateFrom.isSame(dateTo, 'year')) {
      return `${t('date same year', { from: dateFrom, to: dateTo })}`;
    } else {
      return `${t('date diff year', { from: dateFrom, to: dateTo })}`;
    }
  }, [from, to, t]);

  //  Check if selected dates are different then default dates
  const canBeReseted = useMemo(() => {
    return !(dayjs(fromDate).isSame(initFilter.from, 'day') && dayjs(toDate).isSame(initFilter.to, 'day'));
  }, [fromDate, toDate, initFilter]);

  const resetDates = () => {
    if (canBeReseted) {
      setDateErrors({ from: false, to: false, range: false });
      setFromDate(initFilter.from);
      setToDate(initFilter.to);
    }
  };

  const toggleDropdown = (value: boolean) => {
    setIsOpen(value);
  };

  const handleSubmit = () => {
    if (!fromDate || !toDate) {
      setDateErrors({ ...dateErrors, from: !fromDate, to: !toDate });
    } else {
      if (dayjs(toDate).diff(dayjs(fromDate), 'years', true) > 1) {
        return setDateErrors({ ...dateErrors, range: true });
      }
      setDateErrors({ from: false, to: false, range: false });
      setFilters({ from: fromDate, to: toDate });
      toggleDropdown(false);
    }
  };

  const onChangeDate = (d: Date | null, label: string) => {
    if (label === 'from') {
      const from = dayjs(d);
      const to = dayjs(toDate);

      setFromDate(d);
      if (from.isAfter(to)) {
        setToDate(null);
      }
      toDateRef?.current?.input?.focus();
      setDateErrors({ ...dateErrors, from: false, range: false });
    } else {
      const to = dayjs(d);
      const from = dayjs(fromDate);

      setToDate(d);
      if (to.isBefore(from)) {
        setFromDate(null);
        fromDateRef?.current?.input?.focus();
      }
      setDateErrors({ ...dateErrors, to: false, range: false });
    }
  };

  return (
    <DropdownContainer
      label={label}
      isOpen={isOpen}
      icon="calendar-icon"
      iconPosition="left"
      onToggleDropdown={toggleDropdown}
      grayedLabel={true}
    >
      <div className={styles.dateContainer}>
        <div
          className={cx(styles.date, {
            [styles.error]: dateErrors.from || dateErrors.range,
          })}
        >
          <label>{t('from')}</label>
          <DatePicker
            ref={fromDateRef}
            selected={fromDate}
            testId="from-date-select"
            placeholderText="Enter date"
            popperClassName="red-text"
            onChangeRaw={(e) => e.preventDefault()} //  This prevents manually entering and deleting date
            onChange={(d) => onChangeDate(d, 'from')}
            openToDate={!fromDate && toDate ? toDate : undefined} // This open calendar on month where `to` is selected
            customDateFormat={clientConfig.datePickerDateFormat}
          />
        </div>
        <div
          className={cx(styles.date, {
            [styles.error]: dateErrors.to || dateErrors.range,
          })}
        >
          <div>
            <label>{t('to')}</label>
            <DatePicker
              ref={toDateRef}
              testId="to-date-select"
              selected={toDate}
              onChangeRaw={(e) => e.preventDefault()} //  This prevents manually entering and deleting date
              placeholderText="Enter date"
              onChange={(d) => onChangeDate(d, 'to')}
              openToDate={!toDate && fromDate ? fromDate : undefined} // This open calendar on month where `from` is selected
              customDateFormat={clientConfig.datePickerDateFormat}
            />
          </div>
        </div>
      </div>
      {(dateErrors.from || dateErrors.to) && <p className={styles.selectDate}>{t('please select date')}</p>}
      {dateErrors.range && <p className={styles.dateDifference}>{t('date difference')}</p>}
      <p className={cx(styles.resetDates, { [styles.active]: canBeReseted })} onClick={resetDates}>
        {t('reset dates')}
      </p>
      <Button onClick={handleSubmit}>{t('apply')}</Button>
    </DropdownContainer>
  );
};

export default DropdownCustom;
