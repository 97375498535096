export enum ReportType {
  LEAGUES = 'leagues',
  PARTICIPANTS = 'participants',
  PROVIDERS = 'providers',
  PLAYERS = 'players',
  POINTS = 'points',
  RESULTS = 'results',
  ACTIVITY = 'activity',
  ROSTER = 'roster',
}

export const isLeaguesReport = (reportType: ReportType) =>
  [ReportType.LEAGUES, ReportType.PARTICIPANTS, ReportType.PROVIDERS].includes(reportType);
export const isPlayTrackerReport = (reportType: ReportType) =>
  [ReportType.PLAYERS, ReportType.POINTS].includes(reportType);
export const isModeReport = (reportType: ReportType) =>
  [ReportType.RESULTS, ReportType.ACTIVITY, ReportType.ROSTER].includes(reportType);
