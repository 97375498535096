import React from 'react';

import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import AutomaticDownload from 'src/components/generate-report/automatic-download';
import { ReportUploadState } from 'src/components/generate-report/generate-report';
import {
  getPlayTrackerPlayersCSVTransform,
  getPlayTrackerPointsCSVTransform,
} from 'src/components/reports/reports-csv-transforms';
import * as styles from 'src/components/reports/reports.module.less';
import { isLeaguesReport, isPlayTrackerReport, ReportType } from 'src/components/reports/utils';
import { getEnvConfig } from 'src/config/config';
import DownloadSvg from 'src/images/svg-components/usta-download.svg';

export type GeneratedDownloadReportButtonProps = {
  isModeReport: false;
  reportQuery?: string;
  reportUploadState: ReportUploadState;
  onReportUploadStateChange: (state: 'processing' | 'downloadable' | 'none') => void;
  hasData: boolean;
  reportType: ReportType;
  downloadable: boolean;
  setDownloadable: (val: boolean) => void;
  filters?: {
    [key: string]: any;
  };
};

export type ModeReportDownloadProps = {
  isModeReport: true;
  reportType: ReportType;
  modeDownloadUrl?: React.MutableRefObject<string>;
};

type DownloadReportButtonProps = GeneratedDownloadReportButtonProps | ModeReportDownloadProps;

const DownloadReportButton = (props: DownloadReportButtonProps) => {
  const { reportType } = props;
  if (props.isModeReport) {
    return <ModeReportDownload {...props} />;
  } else if (isLeaguesReport(reportType)) {
    return <LeaguesReportGenerator {...props} />;
  } else if (isPlayTrackerReport(reportType)) {
    return <PlayTrackerReportGenerator {...props} />;
  }
  // if report type doesn't support downloading
  return null;
};

const LeaguesReportGenerator = ({
  reportQuery,
  reportUploadState,
  onReportUploadStateChange,
  hasData,
  downloadable,
  setDownloadable,
}: GeneratedDownloadReportButtonProps) => {
  if (!reportQuery) return null;
  return (
    <AutomaticDownload
      reportQuery={reportQuery}
      reportQueryEndpoint={getEnvConfig().SOCIAL_LEAGUES_GQL_URL}
      paginator={{ rootFieldPath: 'report.items' }}
      reportUploadState={reportUploadState}
      onReportUploadStateChange={onReportUploadStateChange}
      generateButtonTitle={<DownloadButton />}
      buttonProps={{ disabled: !hasData }}
      downloadable={downloadable}
      setDownloadable={setDownloadable}
    />
  );
};

const PlayTrackerReportGenerator = ({
  reportQuery,
  filters,
  reportUploadState,
  onReportUploadStateChange,
  reportType,
  hasData,
  downloadable,
  setDownloadable,
}: GeneratedDownloadReportButtonProps) => {
  const { t } = useTranslation();
  if (!reportQuery) return null;

  return (
    <AutomaticDownload
      reportQuery={reportQuery}
      reportQueryEndpoint={getEnvConfig().MESH_GATEWAY_GQL_URL}
      paginator={{
        rootFieldPath: reportType === ReportType.PLAYERS ? 'activityPlayerReport.items' : 'activityPointReport.items',
        pageSize: 1000,
      }}
      reportUploadState={reportUploadState}
      onReportUploadStateChange={onReportUploadStateChange}
      reportQueryVariables={{
        filter: filters,
      }}
      generateButtonTitle={<DownloadButton />}
      buttonProps={{ disabled: !hasData }}
      downloadable={downloadable}
      setDownloadable={setDownloadable}
      csvTransforms={
        reportType === ReportType.PLAYERS ? getPlayTrackerPlayersCSVTransform(t) : getPlayTrackerPointsCSVTransform(t)
      }
    />
  );
};

const ModeReportDownload = ({ modeDownloadUrl }: ModeReportDownloadProps) => {
  const onClick = () => {
    if (modeDownloadUrl && modeDownloadUrl.current) {
      window.location.href = modeDownloadUrl.current;
    }
  };

  return <Button onClick={onClick}>{<DownloadButton />}</Button>;
};

export const DownloadButton: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <DownloadSvg /> <span className={styles.downloadPadding}>{t('download')}</span>
    </>
  );
};

export default DownloadReportButton;
