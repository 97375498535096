import React, { useState } from 'react';

import { Grid } from '@mui/material';
import { print } from 'graphql';
import { useTranslation } from 'react-i18next';
import AdminTable from 'src/components/admin-table/admin-table';
import DatePicker from 'src/components/date-picker/date-picker';
import DropdownCustom from 'src/components/dropdown-custom/dropdown-custom';
import Dropdown from 'src/components/dropdown/dropdown';
import PageHeader from 'src/components/page-header/page-header';
import Panel from 'src/components/panel/panel';
import { Option } from 'src/components/radio-button-group/radio-button-group';
import { DownloadButton } from 'src/components/reports/download-button/download-report-button';
import BtnDownloadAuto from 'src/components/table-accessories/accessories/btn-download-auto';
import { BodyRegularBold } from 'src/components/typography/typography';
import { PageMaxWidth } from 'src/components/util-components/util-components';
import { getEnvConfig } from 'src/config/config';

import { useRankingReports } from './hooks/useRankingReports';
import { REPORT_TYPES, reportConfigs, ReportType } from './ranking-reports-config';
import * as styles from './ranking-reports.module.less';

const RankingReports: React.FC = () => {
  const { t } = useTranslation();
  const reportOptions: Option[] = Object.keys(REPORT_TYPES).map((key) => ({ label: t(REPORT_TYPES[key]), value: key }));

  const [currentReportType, setCurrentReportType] = useState<string>('PLAY_HISTORY');

  const {
    startDate,
    endDate,
    handleSetDates,
    handleSetStartDate,
    handleSetEndDate,
    reportUploadState,
    onReportUploadStateChange,
    paginatedEvents,
    loading,
    controlProps,
    columns,
    downloadable,
    setDownloadable,
    filters,
    handleSetReportType,
  } = useRankingReports(currentReportType);

  const handleReportTypeChange = (type: ReportType) => {
    setCurrentReportType(type);
    handleSetReportType(type);
  };

  return (
    <PageMaxWidth>
      <PageHeader title={t('rankings reports')} />
      <Panel extendedPadding>
        <Grid container spacing={3}>
          {/* Filters Section */}
          <Grid item xs={12} sm={8} container direction="row" spacing={2}>
            <Grid item>
              <BodyRegularBold spacing={{ margins: { xxs: 'bottom' } }}>{t('report type')}</BodyRegularBold>
              <Dropdown
                data-testid="report-type-dropdown"
                options={reportOptions}
                selected={currentReportType}
                onSelect={(type) => handleReportTypeChange(type.value as ReportType)}
              />
            </Grid>
            <Grid item>
              <DropdownCustom from={startDate} to={endDate} setFilters={handleSetDates} initFilter={filters} />
              <BodyRegularBold spacing={{ margins: { xxs: 'bottom' } }}>{t('start date')}</BodyRegularBold>
              <DatePicker onChange={handleSetStartDate} selected={startDate} />
            </Grid>
            <Grid item>
              <BodyRegularBold spacing={{ margins: { xxs: 'bottom' } }}>{t('end date')}</BodyRegularBold>
              <DatePicker onChange={handleSetEndDate} selected={endDate} />
            </Grid>
          </Grid>

          {/* Automatic Download Button */}
          <Grid item xs={12} sm={4} container alignItems="flex-start" justifyContent="flex-end">
            <BtnDownloadAuto
              reportQuery={print(reportConfigs(t)[currentReportType].query)}
              reportQueryEndpoint={getEnvConfig().MESH_GATEWAY_GQL_URL}
              paginator={{
                rootFieldPath: `${currentReportType === 'PLAY_HISTORY' ? 'getPaginatedPlayHistory' : 'getPaginatedBonusPoints'}.items`,
                pageSize: 1000,
              }}
              reportUploadState={reportUploadState}
              onReportUploadStateChange={onReportUploadStateChange}
              reportQueryVariables={{
                filter: filters,
                sort: { field: 'TOURNAMENT_START', direction: 'DESC' },
              }}
              generateButtonTitle={<DownloadButton />}
              downloadable={downloadable}
              setDownloadable={setDownloadable}
              csvTransforms={columns.map((col) => ({
                key: col.key,
                label: col.title,
              }))}
            />
          </Grid>
        </Grid>
      </Panel>
      <Panel extendedPadding classNames={{ container: styles.tableContainer }}>
        <AdminTable
          data={paginatedEvents}
          loading={loading}
          columns={columns}
          controls={controlProps}
          hideTopPaginationInfo
          filters={filters}
        />
      </Panel>
    </PageMaxWidth>
  );
};

export default RankingReports;
