import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AutomaticDownload, { AutomaticDownloadProps } from 'src/components/generate-report/automatic-download';

interface Props extends Omit<AutomaticDownloadProps, 'downloadable' | 'setDownloadable'> {
  downloadable?: boolean;
  setDownloadable?: (val: boolean) => void;
  disableNotifications?: boolean;
  notificationTextSuccess?: string;
  notificationTextLoading?: string;
  notificationTextError?: string;
}

const BtnDownloadAuto = ({
  onReportUploadStateChange,
  onGenerateOrUploadError,
  notificationTextSuccess,
  notificationTextLoading,
  notificationTextError,
  disableNotifications = false,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const handleReportUploadStateChange = useCallback(
    (uploadState, uploadInfo) => {
      onReportUploadStateChange?.(uploadState, uploadInfo);

      if (!disableNotifications) {
        toast.dismiss();
        switch (uploadState) {
          case 'processing':
            toast.info(notificationTextLoading || t('preparing download'), {
              autoClose: false,
              closeButton: false,
            });
            break;
          case 'downloadable':
            toast.success(notificationTextSuccess || t('report downloaded'));
            break;
          default:
            break;
        }
      }
    },
    [disableNotifications, onReportUploadStateChange, notificationTextLoading, notificationTextSuccess],
  );

  const handleReportError = useCallback(
    (error) => {
      onGenerateOrUploadError?.(error);

      if (!disableNotifications) toast.error(notificationTextError || t('notifications.download.error'));
    },
    [disableNotifications, onGenerateOrUploadError, notificationTextError],
  );

  return (
    <AutomaticDownload
      downloadable={true}
      setDownloadable={() => {}}
      {...props}
      onReportUploadStateChange={handleReportUploadStateChange}
      onGenerateOrUploadError={handleReportError}
    />
  );
};

export default BtnDownloadAuto;
