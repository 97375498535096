import React, { SelectHTMLAttributes } from 'react';

import cx from 'classnames';
import Icon from 'src/components/icon/icon';
import { useSpacing } from 'src/hooks/spacing';

import { SpacingProps } from '@clubspark-react/clubspark-react-tools/dist/hooks/spacing';

import * as styles from './dropdown.module.less';

export interface Option {
  label: string;
  value: any;
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement>;

export interface Props extends Pick<SelectProps, Exclude<keyof SelectProps, 'onSelect'>>, SpacingProps {
  options: Option[];
  onSelect?: (option: Option) => any;
  selected?: any;
  field?: {
    name: string;
    value: string;
  };
  selectClassname?: string;
  dropdownClassname?: string;
  setFieldValue?: Function;
  fluid?: boolean;
  darkBorder?: boolean;
  flat?: boolean;
  testId?: string;
}

const Dropdown: React.FC<Props> = ({
  options,
  onSelect,
  selected,
  field,
  setFieldValue,
  selectClassname,
  dropdownClassname,
  fluid,
  spacing,
  flat,
  testId,
  ...props
}) => {
  const spacingClass = useSpacing(spacing);
  const { darkBorder, ...otherProps } = props;
  return (
    <span className={cx(styles.dropdown, spacingClass, dropdownClassname)}>
      <select
        data-testid={testId}
        name={field ? field.name : ''}
        {...otherProps}
        className={cx(styles.select, selectClassname, {
          [styles.fluid]: fluid,
          [styles.selectDisabled]: props.disabled,
          [styles.darkBorder]: darkBorder,
          [styles.flat]: flat,
        })}
        value={field ? field.value : selected}
        onChange={(e) => {
          const option = options.find((o) => o.value === e.target.value);
          option && onSelect?.(option);
          setFieldValue && setFieldValue(field?.name, option?.value);
        }}
      >
        {props?.placeholder && (
          <option value="" className={styles.placeholder}>
            {props.placeholder}
          </option>
        )}
        {options?.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>
      <Icon name="sm-down" className={styles.dropdownIcon} />
    </span>
  );
};

export default Dropdown;
