import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import RankingReports from 'src/components/rankings-reports/ranking-reports';
import SEO from 'src/components/seo';

const RankingReportsPage = () => {
  return (
    <Layout>
      <SEO title="Rankings Reports" />
      <PrivateRoute>
        <RankingReports />
      </PrivateRoute>
    </Layout>
  );
};

export default RankingReportsPage;
